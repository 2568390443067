.sidebar {
  position: absolute;
  margin-top: 10px;
  bottom: 10px;
  width: 70%;
  overflow: hidden;
  z-index: 1000;
  box-shadow: 0 1px 5px rgba(0, 0, 0, 0.65);
  transition: width 0.5s;
  height: 270px;
}

.sidebar.collapsed {
  width: 40px;
  bottom: auto;
  height: auto;
}

/* Estilo del control del zoom */
.leaflet-left {
  left: 71%;
}

.filter-select {
  width: 100%;
}

.sidebar-map-content {
  width: 100%;
}

/* @media (min-width: 768px) and (max-width: 991px) {
  .sidebar {
    width: 260px;
  }
  .sidebar-pane {
    min-width: 265px;
  }
  .filter-select {
    width: 80%;
  }
} */

/* @media (min-width: 992px) and (max-width: 1199px) {
  .sidebar {
    width: 300px;
  }
  .filter-select {
    width: 60%;
  }
} */

@media (min-width: 1200px) {
  /* .sidebar {
    width: 300px;
  }

  .filter-select {
    width: 50%;
  } */
}

.sidebar-left {
  left: 10px;
  bottom: auto;
}

.sidebar-right {
  right: 0;
}

@media (min-width: 768px) {
  /* .sidebar{
    top:74px;
    bottom:10px;
    transition:width .5s
    } */
  .sidebar-left {
    left: 10px;
  }
  .sidebar-right {
    right: 10px;
  }

  .sidebar {
    width: 300px;
  }

  .filter-select {
    width: 200px;
  }

  .sidebar-map-content {
    width: 250px;
  }
}

.sidebar-tabs {
  top: 0;
  bottom: 0;
  height: 100%;
  background-color: #fff;
}

.sidebar-left .sidebar-tabs {
  left: 0;
}

.sidebar-right .sidebar-tabs {
  right: 0;
}

.sidebar-tabs,
.sidebar-tabs > ul {
  /*position:absolute;*/
  width: 40px;
  margin: 0;
  padding: 0;
}

.sidebar-tabs > li,
.sidebar-tabs > ul > li {
  width: 100%;
  height: 40px;
  color: #333;
  font-size: 12pt;
  overflow: hidden;
  transition: all 80ms;
}

.sidebar-tabs > li:hover,
.sidebar-tabs > ul > li:hover {
  color: #000;
  background-color: #eee;
}

.sidebar-tabs > li.active,
.sidebar-tabs > ul > li.active {
  color: #fff;
  background-color: #121e34;
}

.sidebar-tabs > li.disabled,
.sidebar-tabs > ul > li.disabled {
  color: rgba(51, 51, 51, 0.4);
}

.sidebar-tabs > li.disabled:hover,
.sidebar-tabs > ul > li.disabled:hover {
  background: 0 0;
}

.sidebar-tabs > li.disabled > a,
.sidebar-tabs > ul > li.disabled > a {
  cursor: default;
}

.sidebar-tabs > li > a,
.sidebar-tabs > ul > li > a {
  display: block;
  width: 100%;
  height: 100%;
  line-height: 40px;
  color: inherit;
  text-decoration: none;
  text-align: center;
}

.sidebar-tabs > ul + ul {
  bottom: 0;
}

.sidebar-content {
  position: absolute;
  top: 0;
  bottom: 0;
  background-color: rgba(255, 255, 255, 0.95);
  overflow-x: hidden;
  overflow-y: auto;
}

.sidebar-left .sidebar-content {
  left: 40px;
  right: 0;
}

.sidebar-right .sidebar-content {
  left: 0;
  right: 40px;
}

.sidebar.collapsed > .sidebar-content {
  overflow-y: hidden;
}

.sidebar-pane {
  display: none;
  left: 0;
  right: 0;
  box-sizing: border-box;
  padding: 10px 20px;
}

.sidebar-pane.active {
  display: block;
}

.sidebar-header {
  margin: -10px -20px 0;
  height: 40px;
  padding: 0 20px;
  line-height: 40px;
  font-size: 14.4pt;
  color: #fff;
  background-color: #121e34;
}

.sidebar-right .sidebar-header {
  padding-left: 40px;
}

.sidebar-close {
  position: absolute;
  top: 0;
  width: 40px;
  height: 40px;
  text-align: center;
  cursor: pointer;
}

.sidebar-left .sidebar-close {
  right: 0;
}

.sidebar-right .sidebar-close {
  left: 0;
}

/* .sidebar-left~.sidebar-map{margin-left:40px} */

/* .sidebar-right~.sidebar-map{margin-right:40px} */

/* .sidebar.leaflet-touch{
  box-shadow:none;
  border-right:2px solid rgba(0,0,0,.2)
} */

/* para animacion y posicion del control de zoom */
/* .sidebar-left~.sidebar-map .leaflet-left{transition:left .5s}
.sidebar-left.collapsed~.sidebar-map .leaflet-left{left:50px} */
.sidebar-left ~ .sidebar-map {
  margin-left: 0;
}
.sidebar-right ~ .sidebar-map {
  margin-right: 0;
}
.sidebar {
  border-radius: 4px;
}
.sidebar.leaflet-touch {
  border: 2px solid rgba(0, 0, 0, 0.2);
}
.sidebar-left ~ .sidebar-map .leaflet-left {
  transition: left 0.5s;
}
.sidebar-left.collapsed ~ .sidebar-map .leaflet-left {
  left: 50px;
}
.sidebar-right ~ .sidebar-map .leaflet-right {
  transition: right 0.5s;
}
.sidebar-right.collapsed ~ .sidebar-map .leaflet-right {
  right: 50px;
}

@media (min-width: 768px) {
  .sidebar-left ~ .sidebar-map .leaflet-left {
    left: 310px;
  }
}

@media (min-width: 768px) and (max-width: 991px) {
  /* .sidebar-left ~ .sidebar-map .leaflet-left {
    left: 270px;
  } */
  .sidebar-right ~ .sidebar-map .leaflet-right {
    right: 315px;
  }
}

@media (min-width: 992px) and (max-width: 1199px) {
  /* .sidebar-pane {
    min-width: 350px;
  } */
  /* .sidebar-left ~ .sidebar-map .leaflet-left {
    left: 310px;
  } */
  .sidebar-right ~ .sidebar-map .leaflet-right {
    right: 400px;
  }
}

@media (min-width: 1200px) {
  /* .sidebar-pane {
    min-width: 420px;
  } */
  /* .sidebar-left ~ .sidebar-map .leaflet-left {
    left: 310px;
  } */
  .sidebar-right ~ .sidebar-map .leaflet-right {
    right: 470px;
  }
}

/* @media (min-width:768px){
  .sidebar-left~.sidebar-map{margin-left:0}
  .sidebar-right~.sidebar-map{margin-right:0}
  .sidebar{border-radius:4px}
  .sidebar.leaflet-touch{border:2px solid rgba(0,0,0,.2)}
  .sidebar-left~.sidebar-map .leaflet-left{transition:left .5s}
  .sidebar-left.collapsed~.sidebar-map .leaflet-left{left:50px}
  .sidebar-right~.sidebar-map .leaflet-right{transition:right .5s}
  .sidebar-right.collapsed~.sidebar-map .leaflet-right{right:50px}
} */
