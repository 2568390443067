@import '~antd/dist/antd.css';

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.leaflet-container {
  width: 100%;
  height: 100%;
}

.information-panel {
  width: calc(170px + 20vmin);
  z-index: 1000;
  position: absolute;
  margin-top: 10px;
  right: 20px;
  opacity: 0.9;
}

html * {
  font-family: 'Nunito', sans-serif;
}

.header {
  display: flex;
  align-items: center;
  flex: 1;
}

.header-right {
  justify-content: flex-end;
  align-items: center;
  flex: 1;
  display: flex;
}

.header-logo {
  margin-left: 16px;
}

.header-info {
  font-size: calc(8px + 2vmin);
  margin-right: 8px;
  margin-top: 3px;
  color: #fff;
}

.header-info a {
  color: inherit;
  text-decoration: none;
}

.header-title {
  font-size: calc(8px + 2vmin);
  margin-right: 8px;
  color: #fff;
  justify-self: flex-start;
}

.header-selector {
  margin-left: 8px;
  align-items: center;
  width: 180px;
}

.ant-layout-header {
  padding: 0 24px;
  background: #121e34;
}

.information-panel-selector {
  margin-bottom: 10px;
  width: 100%;
}

.leaflet-div-icon {
  background: none !important;
  border: none !important;
}

.cluster-marker {
  color: #fff;
  background: #1978c8;
  border-radius: 50%;
  padding: 10px;
  width: 30px;
  height: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.filter-container {
  margin-top: 10px;
  margin-bottom: 10px;
}
