.about-content {
  margin: 30px;
  font-size: 1.4em;
}

.about-content h1 {
  text-align: center;
}

/* Responsive section */
@media only screen and (max-width: 768px) {
  .about-content p {
    text-align: justify;
  }

  h2 {
    font-size: 1.1em;
  }
}
/* End of Responsive section */
